import { ModulesConfig } from '@eui/core';

export const MODULES: ModulesConfig = {
    core: {
        api: {
            base: '/api',
            user: {
                base: '',
                detail: '/user-details',
                preferences: '/user-preferences',
            },
        },
    },
    pmoMobile: {
        api: {
            setLanguageHeader: 'user-language',
            base: '/apipmob',
            mips: {
                base: '',
                mission: "/mipsplus-service-mission/v1",
                document: "/mipsplus-service-document/v1"
            },
            jsis: {
                base: '/jsis',
            },
        },
    },
};
