import { Component, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

declare let OpenIdConnect: any;

@Component({
    /* tslint:disable-next-line */
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(private zone: NgZone) {
        this.initializeApp();
    }

    initializeApp() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const slug = this.getSlug(event.url);
                if (slug) {
                    this.openLinkOnApp(slug);
                    Browser.close();
                }
            });
        });
    }

    getSlug(url) {
        // Support for normal deep links
        let slug = url.split('pmoforeu://');
        if (Array.isArray(slug) && slug.length > 1) {
            return slug.pop();
        }
        slug = url.split('mypmo://');
        if (Array.isArray(slug) && slug.length > 1) {
            return slug.pop();
        }

        // Support for universal/app links
        slug = url.split(OpenIdConnect.config.spaRedirectUrl).pop();
        if (slug) {
            return (
                'localhost:4200' + (slug.indexOf('/') === -1 ? '/' : '') + slug
            );
        }

        return null;
    }

    openLinkOnApp(slug) {
        if (Capacitor.getPlatform() === 'ios') {
            window.top.location.href = 'capacitor://' + slug;
        } else {
            window.top.location.href = 'http://' + slug;
        }
    }
}
