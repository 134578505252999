// ANGULAR & 3rd PARTIES
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';

// eUI IMPORTS
import {
    CoreModule,
    CoreModuleEffects,
    EUI_CONFIG_TOKEN,
    LocalStorageService,
    StorageService,
    translateConfig,
} from '@eui/core';
// import { UxBreadcrumbsService } from '@eui/components';
import { appConfig } from '../config/index';
import { environment } from '../environments/environment';
// APP ROUTES & ROOT COMPONENTS
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// CSDR CORE - Babel defs & core reducers
import {
    CsdrCoreModule,
    CustomSerializer,
    metaReducers,
    reducerProvider,
    TOKEN,
} from '@csdr/core';
// APP RELATED
import { CoreModule as AppCoreModule } from '@pmob/core';
import {
    menuReducer,
    menuStateSelector,
    notificationListReducer,
    notificationListStateSelector,
    SharedModule as AppSharedModule,
} from '@pmob/shared';
import { JsisInitializationModule } from '@pmob/jsis';
import { MipsInitializationModule } from '@pmob/mips';
import { AgmInitializationModule } from '@pmob/agm';
import {SysperInitializationModule} from "@pmob/sysper";
import { MnetInitializationModule } from '@pmob/mnet';

// APP ROOT MODULE
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
            serializer: CustomSerializer,
        }),
        StoreModule.forRoot(TOKEN, { metaReducers }),
        EffectsModule.forRoot([...CoreModuleEffects]),
        StoreDevtoolsModule.instrument({
            name: 'CSDR App',
            maxAge: 150,
            logOnly: environment.production,
        }),
        // TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: BabelLoader } }),
        TranslateModule.forRoot(translateConfig),
        CoreModule.forRoot(),
        CsdrCoreModule.forRoot(),
        AppCoreModule.forRoot(),
        AppSharedModule,
        AppRoutingModule,
        // PMOB Initialization
        JsisInitializationModule,
        MipsInitializationModule,
        AgmInitializationModule,
        SysperInitializationModule,
        MnetInitializationModule,
        SysperInitializationModule,
        StoreModule.forFeature(menuStateSelector, menuReducer),
        StoreModule.forFeature(
            notificationListStateSelector,
            notificationListReducer,
        ),
        //  *****************
    ],
    declarations: [AppComponent],
    providers: [
        reducerProvider,
        // UxBreadcrumbsService,
        {
            provide: EUI_CONFIG_TOKEN,
            useValue: { appConfig: appConfig, environment: environment },
        },
        // { provide: BABEL_API_CONFIG_TOKEN, useFactory: getBabelApiConfig, deps: [CONFIG_TOKEN] },
        // { provide: BABEL_LOADER_CONFIG_TOKEN, useFactory: getBabelLoaderConfig, deps: [CONFIG_TOKEN] }
        LocalStorageService,
        { provide: StorageService, useExisting: LocalStorageService },
        /*        {
            provide: HTTP_INTERCEPTORS,
            useClass: OpenIdConnectInterceptor,
            multi: true,
        },*/
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
