import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {AppShellComponent,} from '@pmob/core';
// import { FeatureGuardService, AuthGuardService } from '@csdr/core';
import {FeatureGuardService} from '@csdr/core';

const appShellRoutes: Route = {
    path: '',
    component: AppShellComponent,
    // canActivate: [AppRequirementsGuardService],
    children: [
        {
            path: 'home',
            loadChildren: () =>
                import('./features/home-lib.module').then((m) => m.Module),
        },
        {
            path: 'maintenance',
            loadChildren: () =>
                import('./features/maintenance-lib.module').then(
                    (m) => m.Module,
                ),
        },
        {
            path: 'mips',
            loadChildren: () =>
                import('./features/mips-lib.module').then((m) => m.Module),
            data: {featureName: 'mips'},
            canActivate: [FeatureGuardService],
        },
        {
            path: 'jsis',
            loadChildren: () =>
                import('./features/jsis-lib.module').then((m) => m.Module),
            data: {featureName: 'jsis'},
            canActivate: [FeatureGuardService],
        },
        {
            path: 'agm',
            loadChildren: () =>
                import('./features/agm-lib.module').then((m) => m.Module),
            data: {featureName: 'agm'},
            canActivate: [FeatureGuardService],
        },
        {
            path: 'sysper',
            loadChildren: () =>
                import('./features/sysper-lib.module').then((m) => m.Module),
            data: {featureName: 'sysper'},
            canActivate: [FeatureGuardService],
        },
        {
            path: 'mnet',
            loadChildren: () =>
                import('./features/mnet-lib.module').then((m) => m.Module),
            data: {featureName: 'mnet'},
            canActivate: [FeatureGuardService],
        },
        {
            path: 'sysper',
            loadChildren: () =>
                import('./features/sysper-lib.module').then((m) => m.Module),
            data: { featureName: 'sysper' },
            canActivate: [FeatureGuardService],
        },
        {
            path: 'sysper',
            loadChildren: () =>
                import('./features/sysper-lib.module').then((m) => m.Module),
            data: { featureName: 'sysper' },
            canActivate: [FeatureGuardService],
        },
        {
            path: 'mnet',
            loadChildren: () =>
                import('./features/mnet-lib.module').then((m) => m.Module),
            data: { featureName: 'mnet' },
            canActivate: [FeatureGuardService],
        },
        {
            path: 'sysper',
            loadChildren: () =>
                import('./features/sysper-lib.module').then((m) => m.Module),
            data: { featureName: 'sysper' },
            canActivate: [FeatureGuardService],
        },
        /*        {
            path: 'showcase',
            loadChildren: () =>
                import('./features/showcase.module').then(
                    (m) => m.Module),
            data: { featureName: 'showcase' },
            canActivate: [FeatureGuardService],
        },*/
    ],
};

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'index.html', redirectTo: 'home', pathMatch: 'full'},
    appShellRoutes,
    { path: '**', redirectTo: 'home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
